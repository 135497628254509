import * as React from "react"

import { Page, SEO } from "src/components"

const NotFoundPage = () => (
    <Page>
        <SEO title="404: Not found"/>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Page>
)

export default NotFoundPage
